@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sora;
    @apply text-white;
    @apply tracking-wider;
  }
  html {
     scroll-behavior: smooth;
   }

  h1 {
    @apply font-black;
    @apply text-[3rem];
    @apply leading-[4rem];
  }

  h2 {
    @apply font-black;
    @apply text-[2rem];
    @apply leading-[150%];
  }

  h3 {
    @apply font-semibold;
    @apply text-[1.5rem];
    @apply leading-[2rem];
  }

  h4 {
    @apply font-semibold;
    @apply text-[1.25rem];
    @apply leading-[1.5rem];
  }

  h5 {
    @apply font-semibold;
    @apply text-base;
    @apply leading-[1.5rem];
  }

  h6 {
    @apply font-normal;
    @apply text-[0.75rem];
    @apply leading-[1.5rem];
  }

  span {
    @apply font-normal;
    @apply text-[0.875rem];
    @apply leading-[1.5rem];
  }

  a {
    @apply cursor-pointer;
    @apply text-[0.875rem];
    @apply leading-[1.5rem];
  }

  button {
    @apply px-8;
    @apply h-12;
    @apply rounded-primary;
    @apply font-semibold;
    @apply leading-[1.5rem];
  }

  form {
    @apply relative;
  }

  label {
    @apply leading-[1.5rem];
  }

  header {
    @apply text-[0.875rem];
  }

  input, select {
    @apply text-[0.875rem];
    @apply outline-0;
  }

  select {
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    /*-webkit-background-clip: text;*/
    -webkit-text-fill-color: white;
    caret-color: white;
    transition: background-color 1000s ease-out 0s;
  }

    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}


@layer utilities {

  .bg-gradient-bottom-center {
    @apply bg-gradient-to-t from-black via-transparent to-black;
  }
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}

/* third-party libraries CSS */

.tableCheckbox:checked ~ div span {
  @apply opacity-100;
}
.tableCheckbox:checked ~ div {
  @apply bg-primary border-primary;
}
.taskCheckbox:checked ~ .box span {
  @apply opacity-100;
}
.taskCheckbox:checked ~ p {
  @apply line-through;
}
.taskCheckbox:checked ~ .box {
  @apply bg-primary border-primary dark:border-primary;
}

.custom-input-date::-webkit-calendar-picker-indicator {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
}
.custom-input-date-1::-webkit-calendar-picker-indicator {
  background-image: url(./components/Pages/admin/images/icon/icon-calendar.svg);
}
.custom-input-date-2::-webkit-calendar-picker-indicator {
  background-image: url(./components/Pages/admin/images/icon/icon-arrow-down.svg);
}

[x-cloak] {
  display: none !important;
}
